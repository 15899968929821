import React from "react";
import { SectionWrapper } from "../../../assets/styles/ThemeConfig";
import { StyledButton, StyledImage, Title, StyledButtonContainer } from "./PhotoGallery.styled";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  {
    allContentfulZdjeciaStronaGlownaZdjecia {
      edges {
        node {
          zdjeciaStronaGlowna {
            gatsbyImageData
          }
        }
      }
    }
}
`

export const PhotoGallery = () => {
  const data = useStaticQuery(query);
  return (
    <>
    <SectionWrapper id="photo-gallery-music">
      <Title>Zdjęcia</Title>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
      >
        <Masonry gutter={'10px'}>
          {data.allContentfulZdjeciaStronaGlownaZdjecia.edges[0].node.zdjeciaStronaGlowna.map((image, index) => (
            <StyledImage
              key={index}
              image={image.gatsbyImageData}
              alt={`zdjęcie ucznia ${index} w czasie koncertu`}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
      <StyledButtonContainer>
        <StyledButton to="/lekcje-gry-na-gitarze/zdjecia">Zobacz więcej</StyledButton>
      </StyledButtonContainer>
    </SectionWrapper>
        </>
    )
}