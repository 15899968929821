import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const HeroPlaceholder = styled.div`
    max-width: 100%;
    background-color: ${({ theme }) => theme.color.lightGrayText};
    position: relative;

    video {
        width: 100%;
        height: 100%;
    }
`;

export const SocialMediaIconsContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 30%;
    right: 0;
`;

export const HeroTextWrapper = styled.div`
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
`;

export const HeroText = styled.p`
    color: ${({ theme }) => theme.color.white};
    font-size: ${({ theme }) => theme.font.size.paragraph};
    font-weight: 700;
    text-align: justify;
    margin: 0;
    max-width: 320px;

    @media screen and (min-width: 840px) {
        font-size: ${({ theme }) => theme.font.size.headingSmall};
        max-width: 100%;
    }

    @media screen and (min-width: 1500px) {
        max-width: 800px;
    }
`;

export const StyledImage = styled(GatsbyImage)`
        height: 330px;
        width: 560px;
        margin: 0 auto;

        @media screen and (min-width: 840px) {
            order: 2;
            margin: 0;
            img {
                object-fit: contain !important;
            }
        }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &.second-content-wrapper {
        margin-top: 60px;
        margin-bottom: 40px;
        justify-content: flex-start;
    }

    @media screen and (min-width: 840px) {
        justify-content: space-between;
        flex-wrap: nowrap;
    }
`;

export const TextWrapper = styled.div`
    max-width: 460px;
    order: 2;

    @media screen and (min-width: 840px) {
        order: 1;

        &.second-content {
            order: 2;
        }
    }
`;

export const Paragraph = styled.p`
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.font.size.thumbnail};
    text-align: justify;
    font-weight: 500;

    @media screen and (min-width: 840px) {
        text-align: left;
    }

    @media screen and (min-width: 1500px) {
        max-width: 800px;
    }
`;

export const ParagraphList = styled.ul`
    padding: 0;

    @media screen and (min-width: 840px) {
        max-width: 460px;
        order: 2;
        margin-left: 40px;
    }

    @media screen and (min-width: 1500px) {
        max-width: 800px;
    }
`;

export const ParagraphWithIcon = styled.li`
    list-style: none;
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.font.size.paragraph};
    display: flex;
    margin-bottom: 10px;

    ${Paragraph} {
        margin: 0;
    }

    svg {
        min-width: 28px;
    }
`;

export const Title = styled.h2`
    color: ${({ theme }) => theme.color.primaryBlue};
    font-size: ${({ theme }) => theme.font.size.heading};
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
`;

