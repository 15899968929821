import styled from "styled-components";

export const StyledButtonContainer = styled.div`
display: flex;
width: 100%;
text-align: center;
justify-content: center;
`;

export const StyledButton = styled.p`
color: ${({ theme }) => theme.color.darkBlue};
font-size: ${({ theme }) => theme.font.size.paragraph};
outline: none;
border: none;
background-color: ${({ theme }) => theme.color.primaryBlue};
font-weight: 700;
width: 180px;
padding: 10px 20px;
transition: all 0.3s;
margin: 0 auto;
cursor: pointer;
text-decoration: none;
position: absolute;
bottom: 20px;
`;

export const Tile = styled.a`
    width: 240px;
    height: 300px;
    background: #ffffff;
    margin: 30px auto 0;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
    transition: .3s;
    text-decoration: none;
    color: ${({ theme }) => theme.color.darkBlue};
    font-size: ${({ theme }) => theme.font.size.opinionTitle};
    font-weight: 500;
    padding: 20px;
    position: relative;
    text-align: center;
    &:hover {
        box-shadow: 0px 20px 40px rgba(0,0,0,0.4);
    transform: scale(1.05,1.05);

    ${StyledButton} {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.primaryBlue};
  }
    }
    `;

export const TileTitle = styled.h3`
        
    `;

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    `;

