import React from "react";
import { StyledImage, ContentWrapper, Paragraph, ParagraphWithIcon, ParagraphList, Title, HeroPlaceholder } from './Hero.styled';
import { graphql, useStaticQuery } from "gatsby";
import { SectionWrapper } from "../../../assets/styles/ThemeConfig";
import CheckMark from '../../../assets/icons/check-mark.svg';
import HeroVideo from '../../../assets/images/hero-produkcjamuzyczna-video.mp4'

const query = graphql`
 {
    allContentfulZdjecieProdukcjaMuzycznaHero {
        nodes {
          zdjecieStudio {
            gatsbyImageData
          }
        }
      }
  }
`
const Hero = () => {
    const data = useStaticQuery(query);

    return (
        <>
            <HeroPlaceholder>
                <video autoPlay muted loop playsInline>
                    <source src={HeroVideo} type="video/mp4" />
                </video>
            </HeroPlaceholder>
            <Title>Dave Wave Studio</Title>
            <SectionWrapper id="">

                <ContentWrapper className="second-content-wrapper">
                    <StyledImage image={data.allContentfulZdjecieProdukcjaMuzycznaHero.nodes[0].zdjecieStudio.gatsbyImageData} imgStyle={{ objectPosition: 'top' }} alt={'Zdjęcie przedstawiające studio DaveWaveStudio'} />
                    <ParagraphList>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Produkcja muzyczna
                            </Paragraph>
                        </ParagraphWithIcon>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Warsztaty muzyczne
                            </Paragraph>
                        </ParagraphWithIcon>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Nagrywanie + Mixing + Mastering
                            </Paragraph>
                        </ParagraphWithIcon>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Post Produkcja Dźwięku
                            </Paragraph>
                        </ParagraphWithIcon>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Podcasty
                            </Paragraph>
                        </ParagraphWithIcon>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Jingle
                            </Paragraph>
                        </ParagraphWithIcon>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                Spoty Reklamowe
                            </Paragraph>
                        </ParagraphWithIcon>
                        <ParagraphWithIcon>
                            <CheckMark />
                            <Paragraph>
                                ... i wiele więcej
                            </Paragraph>
                        </ParagraphWithIcon>
                    </ParagraphList>
                </ContentWrapper>
            </SectionWrapper>
        </>
    )
}

export default Hero;