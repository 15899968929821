import React from "react";
import { Title, CarouselContainer, StyledImage, StyledLinkContainer, StyledLinksWrapper, SliderWrapper } from "./LogosSlider.styled";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { SectionWrapper } from "../../../assets/styles/ThemeConfig";
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  {
    aniToProjekt: file(name: {eq: "aniToProjekt"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      drakkadr: file(name: {eq: "drakkadr"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      hboCompetition: file(name: {eq: "hboCompetition"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      helios: file(name: {eq: "helios"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      magdalenaAir: file(name: {eq: "magdalenaAir"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      ministerstwo: file(name: {eq: "ministerstwo"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      nationalGeographic: file(name: {eq: "nationalGeographic"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      oskarTomala: file(name: {eq: "oskarTomala"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      pzu: file(name: {eq: "pzu"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      sahara: file(name: {eq: "sahara"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      teatrMiejskiGliwice: file(name: {eq: "teatrMiejskiGliwice"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      tskn: file(name: {eq: "tskn"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      tvp: file(name: {eq: "tvp"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      ulumio: file(name: {eq: "ulumio"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }

      uth: file(name: {eq: "uth"}) {
        childImageSharp {
          gatsbyImageData(width: 600, placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
}
`

const LogosSlider = () => {
  const data = useStaticQuery(query);

  return (
    <>
      <SectionWrapper>
        <Title id="cooperate">Współpraca</Title>
        <CarouselContainer>
          <Carousel showThumbs={false} infiniteLoop>
            <StyledLinksWrapper>
              <SliderWrapper>
                <StyledLinkContainer href="https://www.youtube.com/watch?v=vphSSPLV0Gc" target={'_blank'}><StyledImage image={data.pzu.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>

              <SliderWrapper>
                <StyledLinkContainer href="https://www.national-geographic.pl/artykul/rok-w-
tatrach-zobacz-gorskie-krajobrazy-w-czterech-odslonach" target={'_blank'}><StyledImage image={data.nationalGeographic.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>

              <SliderWrapper>
                <StyledLinkContainer href="https://teatr.gliwice.pl/wydarzenie/nina-i-paul/" target={'_blank'}><StyledImage image={data.teatrMiejskiGliwice.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>
            </StyledLinksWrapper>
            <StyledLinksWrapper>
              <SliderWrapper>
                <StyledLinkContainer href="https://opole.tvp.pl/51926901/film-dokumentalny-w-starej-koszuli-z- odwaga-w-sercu?
fbclid=IwAR0H8G3DhRm7gfjPzXBc5ZGe4vrgslVuq26dfxrTBLuq2_iHYqiPpjYl
pOU" target={'_blank'}><StyledImage image={data.tvp.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>

              <SliderWrapper>
                <StyledLinkContainer href="https://www.youtube.com/watch?v=mr06AdbxnA0" target={'_blank'}><StyledImage image={data.hboCompetition.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>

              <SliderWrapper>
                <StyledLinkContainer><StyledImage image={data.helios.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>
            </StyledLinksWrapper>
            <StyledLinksWrapper>
              <SliderWrapper>
                <StyledLinkContainer href="https://www.youtube.com/watch?v=-NGyAFz1vyY" target={'_blank'}><StyledImage image={data.tskn.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>

              <SliderWrapper>
                <StyledLinkContainer href="https://fb.watch/eMYYOS2FVI/" target={'_blank'}><StyledImage image={data.ministerstwo.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>

              <SliderWrapper>
                <StyledLinkContainer href="https://ulumio.com/pl/dawid-mazurek/" target={'_blank'}><StyledImage image={data.ulumio.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>
            </StyledLinksWrapper>
            <StyledLinksWrapper>
              <SliderWrapper>
                <StyledLinkContainer href="https://www.youtube.com/watch?v=VjHvK-IF9m8" target={'_blank'}><StyledImage image={data.drakkadr.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>
              <SliderWrapper>
                <StyledLinkContainer href="https://www.youtube.com/watch?v=1gCH6y0Dynw" target={'_blank'}><StyledImage image={data.sahara.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>
              <SliderWrapper>
                <StyledLinkContainer href="https://open.spotify.com/track/1PqpQsQ091rEqx3F4uUIpu?
si=f9444e2d0d1c4869" target={'_blank'}><StyledImage image={data.aniToProjekt.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>
            </StyledLinksWrapper>
            <StyledLinksWrapper>
              <SliderWrapper>
                <StyledLinkContainer href="https://www.youtube.com/watch?v=bSCYjLt_MDs" target={'_blank'}><StyledImage image={data.oskarTomala.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>
              <SliderWrapper>
                <StyledLinkContainer href="https://open.spotify.com/album/
2AOqFFBB4HHsTK2oduR7Ty?si=He6DhL6cTgGaeDTkd6RSNQ" target={'_blank'}><StyledImage image={data.magdalenaAir.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>
              <SliderWrapper>
                <StyledLinkContainer href="https://vimeo.com/220932532?
fbclid=IwAR0tNkeudXyAvO0P21zPksCPwuqC0onmck-1j8GBVBzHXxnw0dB WKbwoLf4" target={'_blank'}><StyledImage image={data.uth.childImageSharp.gatsbyImageData} alt={`logo zespołu`} /></StyledLinkContainer>
              </SliderWrapper>
            </StyledLinksWrapper>
          </Carousel>
        </CarouselContainer>
      </SectionWrapper>
    </>
  )
}

export default LogosSlider;