import React from "react";
import Contact from "../components/Common/Contact/Contact";
import { AboutUs } from "../components/MusicProductionPage/AboutUs/AboutUs";
import Hero from "../components/MusicProductionPage/Hero/Hero";
import LogosSlider from "../components/MusicProductionPage/LogosSlider/LogosSlider";
import { PhotoGallery } from "../components/MusicProductionPage/PhotoGallery/PhotoGallery";
import Tiles from "../components/MusicProductionPage/Tiles/Tiles";

export default function MusicProduction() {
  return (
    <>
    <Hero />
    <AboutUs />
    <LogosSlider />
    <Tiles />
    <PhotoGallery />
    <Contact />
    </>
  )
}
