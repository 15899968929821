import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const Title = styled.h2`
    color: ${({ theme }) => theme.color.primaryBlue};
    font-size: ${({ theme }) => theme.font.size.heading};
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
`;

export const ContentWrapper = styled.div`
    display: grid;
`;

export const StyledImage = styled(GatsbyImage)`
    /* max-width: 280px; */
    max-height: 340px;
    object-fit: contain;
    margin: 0 auto;
`;

export const SliderWrapper = styled.div`
    width: 100%;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1200px) {
    flex-wrap: nowrap;
    margin: 0 7px;
    }
`;

export const StyledLinksWrapper = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;

@media screen and (min-width: 1200px) {
    width: 90%;
    flex-wrap: nowrap;
    margin: 0 auto;
}
`;

export const StyledLinkContainer = styled.a`
width:100%;
`;

export const CarouselContainer = styled.div`
    /* max-width: 400px; */
    margin: 0 auto;
    .carousel-root {
        .carousel.carousel-slider {
            .control-dots {
                .dot {
                    background-color: ${({ theme }) => theme.color.primaryBlue};
                }
            }
            .control-arrow {
                opacity: 1;
                &:hover {
                    background: transparent;
                }
                &.control-next {
                    &::before {
                    border-left: 16px solid ${({ theme }) => theme.color.darkBlue};
                    border-top: 16px solid transparent;
                    border-bottom: 16px solid transparent;
                    }
                }

                &.control-prev {
                    &::before {
                    border-right: 16px solid ${({ theme }) => theme.color.darkBlue};
                    border-top: 16px solid transparent;
                    border-bottom: 16px solid transparent;
                    }
                }
            }

            .carousel-status {
                display: none;
            }
        }
    }
`;


export const StyledButtonContainer = styled.div`
    display: flex;
    width: 100%;
    text-align: center;
`;

export const StyledButton = styled.a`
  color: ${({ theme }) => theme.color.darkBlue};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.color.primaryBlue};
  font-weight: 700;
  width: 180px;
  padding: 10px 20px;
  transition: all 0.3s;
  margin: 40px auto;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.primaryBlue};
  }
`;