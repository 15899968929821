import React, { useState, useEffect } from "react"
import { SectionWrapper } from '../../../assets/styles/ThemeConfig';
import { Tile, Wrapper, StyledButtonContainer, StyledButton } from './Tiles.styled';



const Tiles = () => {
    const [pdf1Url, setPdf1Url] = useState('');
    const [pdf2Url, setPdf2Url] = useState('');
    const [pdf3Url, setPdf3Url] = useState('');

    useEffect(() => {
        fetch(`https://cdn.contentful.com/spaces/mf4yl096t3pn/environments/master/entries?access_token=S5Aa5RVFk8LJmSgIN9EGFcchcAFWi2mORl1MHMexpw8&include=2&content_type=linkDoPdFaProdukcjaMuzyczna`)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                if (resultData && resultData.includes) {
                    setPdf1Url(resultData.includes.Asset[0].fields.file.url)
                }
            })
    }, [])

    useEffect(() => {
        fetch(`https://cdn.contentful.com/spaces/mf4yl096t3pn/environments/master/entries?access_token=S5Aa5RVFk8LJmSgIN9EGFcchcAFWi2mORl1MHMexpw8&include=2&content_type=linkDoPdFaPoKlikuWKafelekOrganizacjaWarszt`)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                if (resultData && resultData.includes) {
                    setPdf2Url(resultData.includes.Asset[0].fields.file.url)
                }
            })
    }, [])

    useEffect(() => {
        fetch(`https://cdn.contentful.com/spaces/mf4yl096t3pn/environments/master/entries?access_token=S5Aa5RVFk8LJmSgIN9EGFcchcAFWi2mORl1MHMexpw8&include=2&content_type=linkDoPdFaProdukcjaMuzycznaDlaMediw`)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                if (resultData && resultData.includes) {
                    setPdf3Url(resultData.includes.Asset[0].fields.file.url)
                }
            })
    }, [])

    return (
        <>
            <SectionWrapper>
                <Wrapper>
                    <Tile href={`https://${pdf1Url}`} target="_blank">
                        Produkcja muzyczna
                        <StyledButtonContainer>
                            <StyledButton>Pobierz informację</StyledButton>
                        </StyledButtonContainer>
                    </Tile>
                    <Tile href={`https://${pdf2Url}`} target="_blank">
                        Organizacja warsztatów muzycznych
                        <StyledButtonContainer>
                            <StyledButton>Pobierz informację</StyledButton>
                        </StyledButtonContainer>
                    </Tile>
                    <Tile href={`https://${pdf3Url}`} target="_blank">
                        Produkcja muzyczna dla mediów
                        <StyledButtonContainer>
                            <StyledButton>Pobierz informację</StyledButton>
                        </StyledButtonContainer>
                    </Tile>
                </Wrapper>
            </SectionWrapper>
        </>

    )
};

export default Tiles;