import React from "react";
import { SectionWrapper } from "../../../assets/styles/ThemeConfig";
import { Title, TextWrapper, StyledImage, ContentWrapper, Paragraph } from './AboutUs.styled';
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
 {
    allContentfulZdjecieProdukcjaMuzycznaDlaczegoMy {
        nodes {
          zdjecieProdukcjaMuzyczna {
            gatsbyImageData
          }
        }
      }
  }
`

export const AboutUs = () => {
    const data = useStaticQuery(query);
    return (
        <>
            <SectionWrapper id="about-us-music">
                <Title>Dlaczego my</Title>
                <ContentWrapper>
                    <TextWrapper>
                        <Paragraph>Dave Wave współpracuje ze studentami oraz absolwentami najlepszych akademii muzycznych w Polsce, którzy na codzień zajmują się przeróżnymi dziedzinami muzyki. Tworząc kolektyw kompozytorów, aranżerów, producentów muzycznych, inżynierów dźwięku oraz muzyków tworzymy niespotykaną mieszankę gotową, na najtrudniejsze wyzwania muzyczne oraz dźwiękowe. Nie ma dla nas znaczenia, czy celem projektu będzie stworzenie krótkiego jingle czy nagranie orkiestry symfonicznej w systemie dolby Atmos - do każdego projektu podchodzimy równie profesjonalnie.</Paragraph>
                    </TextWrapper>
                    <StyledImage image={data.allContentfulZdjecieProdukcjaMuzycznaDlaczegoMy.nodes[0].zdjecieProdukcjaMuzyczna.gatsbyImageData} imgStyle={{ objectPosition: 'top' }} alt={'Zdjęcie przedstawiające studio DaveWaveStudio'}/>
                </ContentWrapper>
            </SectionWrapper>
        </>
    )
};
